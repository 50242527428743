<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-row>
          <b-col cols="12">
            <b-card
              v-if="data.checkin"
              :title="$t('Create New Check')"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    v-for="(color,index) in colors"
                    :key="index"
                    class="sm"
                    :variant="color"
                    @click="changeColorTo(index)"
                  >{{ index }}
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="data.color"
                      class="mt-2"
                      :state="errors.color ?false:null"
                      :placeholder="$t('Color')"
                    />
                    <small
                      v-if="errors.color"
                      class="text-danger"
                    >{{ $t(errors.color[0]) }}</small>
                    <label>{{ $t('Color') }}</label>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="form-label-group">
                    <cleave
                      v-model="data.km"
                      class="form-control mt-2"
                      :raw="false"
                      :options="{
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                        numeralDecimalScale: 0,
                      }"
                      :placeholder="$t('Km')"
                      :state="errors.km ?false:null"
                      :class="errors.km ?'is-invalid':null"
                    />
                    <small
                      v-if="errors.km"
                      class="text-danger"
                    >{{ $t(errors.km[0]) }}</small>
                    <label>{{ $t('Km') }}</label>
                  </div>
                </b-col>
                <b-col
                  v-if="datanew"
                  cols="12"
                  md="6"
                >
                  <div class="form-label-group">
                    <cleave
                      v-model="data.plate_fee"
                      class="form-control mt-2"
                      :raw="false"
                      :options="{
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                        numeralDecimalScale: 0,
                      }"
                      :placeholder="$t('Plate_fee')"
                      :state="errors.plate_fee ?false:null"
                      :class="errors.plate_fee ?'is-invalid':null"
                      disable
                    />
                    <small
                      v-if="errors.plate_fee"
                      class="text-danger"
                    >{{ $t(errors.plate_fee[0]) }}</small>
                    <label>{{ $t('Plate_fee') }}</label>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="form-label-group">
                    <cleave
                      v-model="data.other_fee"
                      class="form-control mt-2"
                      :raw="false"
                      :options="{
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                        numeralDecimalScale: 0,
                      }"
                      :placeholder="$t('other_fee')"
                      :state="errors.other_fee ?false:null"
                      :class="errors.other_fee ?'is-invalid':null"
                      disable
                    />
                    <small
                      v-if="errors.other_fee"
                      class="text-danger"
                    >{{ $t(errors.other_fee[0]) }}</small>
                    <label>{{ $t('other_fee') }}</label>
                  </div>
                </b-col>
                <b-col
                  v-if="!datanew"
                  cols="12"
                  md="6"
                >
                  <div class="form-label-group">
                    <cleave
                      v-model="data.renew_fee"
                      class="form-control mt-2"
                      :raw="false"
                      :options="{
                        numeral: true,
                        numeralThousandsGroupStyle: 'none',
                        numeralDecimalScale: 0,
                      }"
                      :placeholder="$t('Renew_fee')"
                      :state="errors.renew_fee ?false:null"
                      :class="errors.renew_fee ?'is-invalid':null"
                    />
                    <small
                      v-if="errors.renew_fee"
                      class="text-danger"
                    >{{ $t(errors.renew_fee[0]) }}</small>
                    <label>{{ $t('Renew_fee') }}</label>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="data.contract_number"
                      class="mt-2"
                      :state="errors.contract_number ?false:null"
                      :placeholder="$t('Contract_number')"
                    />
                    <small
                      v-if="errors.contract_number"
                      class="text-danger"
                    >{{ $t(errors.contract_number[0]) }}</small>
                    <label>{{ $t('Contract_number') }}</label>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="data.notes"
                      class="mt-2"
                      :state="errors.notes ?false:null"
                      :placeholder="$t('notes')"
                    />
                    <small
                      v-if="errors.notes"
                      class="text-danger"
                    >{{ $t(errors.notes[0]) }}</small>
                    <label>{{ $t('notes') }}</label>
                  </div>
                </b-col>
                <b-col
                  v-if="datanew"
                  cols="12"
                  md="6"
                >
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="data.new"
                      :value="true"
                      :state="errors.new ?false:null"
                    >
                      {{ data.new ? $t('New Check') : $t('Old Check') }}
                    </b-form-checkbox>
                  </div>
                  <small
                    v-if="errors.new"
                    class="text-danger"
                  >{{ $t(errors.new[0]) }}</small>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-button
                    v-for="el in [3,6,9,12]"
                    :key="el"
                    class="m-1"
                    variant="primary"
                    @click="ExpirationDateAfter(el)"
                  >
                    {{ $t('after') }} {{ el }} {{ $t('month') }}
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div>
                    <label>{{ $t('Expiration_date') }}</label>
                    <b-form-datepicker
                      v-model="data.expiration_date"
                      :placeholder="$t('Expiration_date')"
                      :state="errors.expiration_date ?false:null"
                    />
                    <small
                      v-if="errors.expiration_date"
                      class="text-danger"
                    >{{ $t(errors.expiration_date[0]) }}</small>
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              v-else
              :title="$t('please firstly chose Checkin')"
            />
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-card
              v-if="data.checkin"
              :title="$t('Checkin')"
            >
              <b-row>
                <b-col
                  v-for="el in ['no','chassis_number','check_in_date','cr','name','type']"
                  :key="el"
                  cols="4"
                >
                  <!-- static text -->
                  <b-form-group>
                    {{ $t(el) }}
                    <b-form-input
                      plaintext
                      :value="$t(SelectedCheckin(data.checkin)[el])"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="SelectedCheckin(data.checkin).ebroker"
                  cols="4"
                >
                  <!-- static text -->
                  <b-form-group
                    :label="$t('ebroker')"
                  >
                    <b-form-input
                      plaintext
                      :value="SelectedCheckin(data.checkin).ebroker_data.name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="SelectedCheckin(data.checkin).ibroker"
                  cols="4"
                >
                  <!-- static text -->
                  <b-form-group
                    :label="$t('ibroker')"
                  >
                    <b-form-input
                      plaintext
                      :value="SelectedCheckin(data.checkin).ibroker_data.name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="SelectedCheckin(data.checkin).owner"
                  cols="4"
                >
                  <!-- static text -->
                  <b-form-group
                    :label="$t('owner')"
                  >
                    <b-form-input
                      plaintext
                      :value="SelectedCheckin(data.checkin).owner_data.name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="SelectedCheckin(data.checkin).owner"
                  cols="4"
                >
                  <!-- static text -->
                  <b-form-group
                    :label="$t('owner identify')"
                  >
                    <b-form-input
                      plaintext
                      :value="SelectedCheckin(data.checkin).owner_data.identify"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-card
              v-if="data.checkin"
              :title="$t('Car')"
            >
              <b-row>
                <b-col
                  v-for="el in ['manufacturer','model','car_type','motor_power','motor_size','motor_type','name','passenger','wight']"
                  :key="el"
                  cols="4"
                >
                  <!-- static text -->
                  <b-form-group>
                    {{ $t(el) }}
                    <b-form-input
                      plaintext
                      :value="$t(SelectedCheckin(data.checkin).car_data[el])"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

          </b-col>
          <b-col
            v-if="data.checkin"
            cols="12"
          >
            <b-card :title="$t('Checks')">
              <b-table-lite
                v-if="SelectedCheckin(data.checkin).checks.length>0"
                hover
                :items="SelectedCheckin(data.checkin).checks"
              />
              <div
                v-else
                class="text-center"
              >
                {{ $t('No Data Available') }}
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Checkins')">
              <label>{{ $t('please select...') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter"
                />
              </b-input-group>
              <v-select
                v-model="data.checkin"
                :options="Checkins"
                :reduce="Checkins => Checkins.id"
                label="name"
              />
              <small
                v-if="errors.checkin"
                class="text-danger"
              >{{ $t(errors.checkin[0]) }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Owners')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.owner"
                :options="Owners"
                :reduce="Owners => Owners.id"
                label="name"
              />
              <small
                v-if="errors.owner"
                class="text-danger"
              >{{ $t(errors.owner[0]) }}</small>
            </b-card>
          </b-col>
          <b-col
            v-if="datanew"
            cols="12"
          >
            <b-card :title="$t('Plates')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.plate"
                :options="Plates"
                :reduce="Plates => Plates.id"
                label="name"
              />

              <small
                v-if="errors.plate"
                class="text-danger"
              >{{ $t(errors.plate[0]) }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('invoice')">
              {{ $t('Total') }} : {{ Total }}<br>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="clearData()"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: null,
      checkin: null,
      filter2: null,
      colors: {
        أخضر: 'success',
        برتقالي: 'warning',
        أحمر: 'danger',
        أزرق: 'info',
        فضي: 'secondary',
        أسود: 'dark',
        أحمر_فاتح: 'danger',
        ذهبي: 'warning',
        أزرق_سماوي: 'info',
        أبيض: 'light',
        أصفر: 'warning',
        زيتي: 'success',
        جردوني: 'light',
        خمري: 'danger',
        أزرق_فاتح: 'secondary',
        كموني_فاتح: 'success',
        بنفسجي: 'light',
        أصفر_فاتح: 'warning',
        كحلي: 'light',
        بني: 'dark',
        كرزي: 'danger',
      },
      data: {},
      errors: [],
      fileErrors: '',
      datanew: false,

    }
  },
  computed: {
    pagination() {
      return `page=${this.currentPage}&search=${this.filter2}`
    },
    Total() {
      let plateFee = 0
      let renewFee = 0
      let otherFee = 0
      if (this.data.plate_fee) plateFee = parseInt(this.data.plate_fee, 10)
      if (this.data.renew_fee) renewFee = parseInt(this.data.renew_fee, 10)
      if (this.data.other_fee) otherFee = parseInt(this.data.other_fee, 10)
      return plateFee + renewFee + otherFee
    },
    Fee() {
      return this.$store.getters['fee/GetElements']
    },
    Checkins() {
      return this.$store.getters['checkin/GetElements']
    },

    Owners() {
      return this.$store.getters['owner/GetElements']
    },

    Plates() {
      return this.$store.getters['plate/GetElements']
    },
  },
  watch: {
    /* eslint-disable */
        'data.renew_fee': function (val) {
            if (val) {
                this.data.plate_fee = null
                this.data.pay_fee = null

            }
        },

        'data.checkin': function (val) {
            if (typeof(val) === "object"){
                let vv = "object"
                val =parseInt(val[0].id)
            }
            this.data.plate_fee = 0
            this.data.renew_fee = 0
            this.data.other_fee = 0
            if (val) {
                let SelectedCheckins = this.Checkins
                SelectedCheckins = SelectedCheckins.find(el => el.id === val)
                this.data.filter = SelectedCheckins.chassis_number
                this.data.filter = SelectedCheckins.chassis_number
                this.data.owner = SelectedCheckins.owner_data.id
                this.data.color = SelectedCheckins.color

                if (SelectedCheckins.checks_count > 0) {
                    //have old checks
                    this.data.color = SelectedCheckins.checks[0].color
                    this.data.expiration_date = this.moment(SelectedCheckins.checks[0].expiration_date).add(3, 'months').format('YYYY-MM-DD')
                    this.data.km = SelectedCheckins.checks[0].km
                    this.data.contract_number = SelectedCheckins.checks[0].contract_number
                    //disable new old
                    //disable (import plate custom) fee+
                    //disable new old and make it false
                    this.datanew = false
                    this.data.renew_fee = this.Fee.renewFee
                    this.data.plate_fee = null
                    this.data.pay_fee = null
                    this.data.owner = SelectedCheckins.checks[0].owner
                }
                else {//dont have have old checks
                    this.data.new=true
                    this.data.new=false
                    this.data.renew_fee = null
                    this.data.pay_fee = null
                    this.data.plate_fee = this.Fee.old
                   if (SelectedCheckins.created_year > 2020) this.data.plate_fee = this.Fee.new
                    if (SelectedCheckins.created_year > 2020) this.data.new= true
                    this.datanew = true
                    this.data.plate=this.Plates[0].id
                    //disable renew fee
                    //disable new old and make it true

                }
            }
            else {
                this.clearData()
            }
        },
        'data.new': function (val) {
            if (val === true) {
                this.data.plate_fee = this.Fee.new
                this.data.pay_fee = null
                this.data.renew_fee = null
            } else {
                this.data.plate_fee = this.Fee.old
                this.data.pay_fee = null
                this.data.renew_fee = null
            }
        },
        /* eslint-enable */
  },
  created() {
    this.clearData()
    this.getElementdata()
    this.data.expiration_date = this.moment().add(3, 'months').format('YYYY-MM-DD')
    this.$store.dispatch('fee/GetPluckedElements')
    this.$store.dispatch('checkin/GetElements')

    this.$store.dispatch('owner/getOwners')

    this.$store.dispatch('plate/GetAvailableElements')
  },
  methods: {
    onEnter() {
      this.filter2 = this.checkin
      this.currentPage = 1
      this.getElementdata()
    },
    getElementdata() {
      this.$store.dispatch('checkin/GetElements', `page=1&search=${this.data.filter}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkin = response.data.checkins[0].id
      })
    },
    clearData() {
      this.errors = []
      this.data = {
        plate_fee: 0,
        renew_fee: 0,
        pay_fee: 0,
        other_fee: 0,
        contract_number: '',
        expiration_date: this.moment().add(3, 'months').format('YYYY-MM-DD'),
        owner: null,
        plate: null,
        km: '',
        color: '',
        new: false,
        checkin: this.checkin,
      }
    },
    SelectedCheckin(val) {
      return this.Checkins.find(el => el.id === val)
    },
    changeColorTo(val) {
      this.data.color = val
    },
    setBoolean(val) {
      if (this.data[val] === undefined) {
        this.data[val] = false
      }
    },
    ExpirationDateAfter(months) {
      this.data.expiration_date = this.moment().add(months, 'months').format('YYYY-MM-DD')
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      if ((this.data.plate === null || this.data.plate === '') && this.datanew) {
        alert('plate is required')
      } else {
        if (this.data.other_fee === 0 || this.data.other_fee === '0') this.data.other_fee = null
        this.$store.dispatch('check/Create', this.data).then(() => {
          this.Plates.splice(this.Plates[0], 1)
          this.clearData()
        }).catch(error => {
          this.errors = error.response.data.errors
        })
      }
    },
  },
}
</script>
